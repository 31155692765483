var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('configurable-table',{attrs:{"report":_vm.report},scopedSlots:_vm._u([{key:"total",fn:function(){return [_c('a-dashboard',{attrs:{"api":_vm.capital.getAlreadySettleInfo,"options":[
        {
          title: '已结算货款总额',
          key: 'settleGoodAmount',
        },
        {
          title: '已结算佣金总额',
          key: 'settleCommissionAmount'
        } ]}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }