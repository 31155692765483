<template>
  <div style="display: flex; padding-bottom: 20px;">
    <a-statistic v-for="o in options" :key="o.key"
      style="margin-right: 150px;"
      :value="data[o.key]"
      suffix="元"
    >
      <template #title>
        <p class="title">
          {{o.title}}
        </p>
      </template>
    </a-statistic>

    <a-button shape="circle" icon="sync" size="small" @click="getData" :loading="loading"></a-button>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
export default {
  name: 'ADashBoard',
  props: {
    api: Function,
    options: Array,
  },
  setup (props) {
    const data = ref(props.options.reduce((acc, x) => ({
      ...acc,
      [x.key]: 0
    }), {}))
    const loading = ref(false)

    onMounted(getData)

    async function getData () {
      loading.value = true
      const res = await props.api()
      loading.value = false
      data.value = res.data
    }

    return {
      data,
      loading,
      getData,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
